<template>
  <div>
    <!-- <el-input ref="phoneNumber" :placeholder="$t('i18nn_29799f539ee6b906')" v-model="phoneNumber" name="hyUserCode" maxlength="11" class="input-with-select"> -->
    <!-- <el-select v-model="ruleForm.areaCode" slot="prepend" :placeholder="$t('i18nn_2f647e039073d7d4')" style="width:150px">
                    <el-option-group v-for="(group,index) in areaCodeList" :key="index" :label="group.label">
                      <el-option v-for="(item,index2) in group.options" :key="index2" :label="item.code+' '+item.codeText" :value="item.codeText">
                        <div class="areaCodeOption clearfix">
                          <span class="areaCodeOptionName">{{ item.code }}</span>
                          <span class="areaCodeOptionCode">{{ $Utils.i18nCodeText(item) }}</span>
                        </div>
                      </el-option>
                    </el-option-group>
                  </el-select> -->
    <el-popover placement="bottom" width="400" trigger="click" :ref="'popover_areaCode'">
      <!-- <el-input :placeholder="$t('i18nn_fbc06cfe55187764')" v-model="serArea" @change="changeSerArea" /> -->
      <div class="selAreaKeyCon">
        <ul>
          <li @click="selAreaHot()" :class="{'activeAreaKey':!!isShowHot}"><!-- 常用国家/地区 -->{{$t("module.HyAreaCodeSel.DefaMsg")}}</li>
          <li v-for="(item,index) in areaKeyList" :key="index" @click="selAreaKey(item)" :class="{'activeAreaKey':!isShowHot && selkey===item}">
            {{item}}
          </li>
        </ul>
      </div>
      <div class="line_separate">
        <span v-if="!!isShowHot"><!-- 常用国家/地区 -->{{$t("module.HyAreaCodeSel.DefaMsg")}}</span>
        <span v-else>{{selkey}}</span>
        <i class="line_separate_icon"></i>
        <!-- <hr /> -->
      </div>
      <div v-if="!!isShowHot && ''==selkey" class="areaCodeListCon">
        <ul>
          <li v-for="(item,index) in hotAreaCodeList" :key="index" @click="selData(item)">
            <span class="areaCodeVal">+{{ $Utils.i18nCodeText(item) }}</span>
            <span class="areaCodeName">{{ item.code }}</span>

          </li>
        </ul>
      </div>
      <div v-else class="areaCodeListCon">
        <ul>
          <li v-for="(item,index) in allAreaCodeListShow" :key="index" @click="selData(item)">
            <span class="areaCodeVal">+{{ $Utils.i18nCodeText(item) }}</span>
            <span class="areaCodeName">{{ item.code }}</span>

          </li>
        </ul>
      </div>
      <!-- <el-select v-model="ruleForm.areaCode" :placeholder="$t('i18nn_2f647e039073d7d4')" style="width:150px">
                      <el-option-group v-for="(group,index) in areaCodeList" :key="index" :label="group.label">
                        <el-option v-for="(item,index2) in group.options" :key="index2" :label="item.code+' '+item.codeText" :value="item.codeText">
                          <div class="areaCodeOption clearfix">
                            <span class="areaCodeOptionName">{{ item.code }}</span>
                            <span class="areaCodeOptionCode">{{ $Utils.i18nCodeText(item) }}</span>
                          </div>
                        </el-option>
                      </el-option-group>
                    </el-select> -->
      <el-button slot="reference" style="padding: 12px 5px">
        <!-- <span class="AreaNameCon">{{areaCodeTextShow}}</span> -->
        <span class="AreaCodeCon">+{{areaCodeShow}}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
    </el-popover>
    <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
    <!-- </el-input> -->
  </div>
</template>
<script>
export default {

  name: 'CashWithdrawalMsg',

  props: {
    areaCode: {
      type: String,
      // required: true
    },
    areaCodeText: {
      type: String,
      // required: true
    },
    // phoneNumber:{
    //   type: String,
    // }
    // accountType 2-厂家 4-个人

  },
  watch: {
    areaCode: function(newVal, oldVal) {
      this.initData();
    }
  },
  data() {
    return {
      areaCodeShow: "",
      areaCodeTextShow: "",

      // areaCode:"",
      // phoneNumber:"",
      serArea: "",
      areaCodeList: this.$store.state.areaCodeList,
      hotAreaCodeList: [],
      allAreaCodeList: [],
      allAreaCodeListShow: [],
      //ABCDEFGHIJKLMNOPQRSTUVWXYZ
      // areaKeyList:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
      areaKeyList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
      selkey: '',
      isShowHot: true,
      // dialogFormVisible: false,
      // widthS: '100%',
      // heightS: '100%',
      // srcShow: '',
      // loading: false,
      // listData:[]
    }
  },
  // watch: {
  //     src: function (newVal, oldVal) {
  //       this.initData();
  //     }
  //   },
  created() {
    console.log("created");
    //手机区域代码
    this.$store.dispatch('UPDATE_PHONE_AREACODE_DATA').then(res => {
        console.log("手机区域代码成功", res);
        this.areaCodeList = res;
        this.hotAreaCodeList = res[0].options;
        this.allAreaCodeList = res[1].options;
      })
      .catch(res => {
        console.log("手机区域代码请求失败", res);
        // this.$message.warning('手机区域代码请求失败');
        this.$message.warning(this.$t("module.HyAreaCodeSel.errorMsg"));
      });
  },
  mounted() {
    console.log('monted');
    this.initData();

  },
  methods: {
    initData() {
      // this.getData();
      this.areaCodeShow = this.areaCode;
      this.areaCodeTextShow = this.areaCodeText;
    },
    //切换到热门
    selAreaHot() {
      this.isShowHot = true;
      this.selkey = "";
      // console.log(this.hotAreaCodeList);
    },
    //搜索
    selAreaKey(key) {
      console.log(key);
      this.isShowHot = false;
      this.selkey = key;
      // console.log(this.allAreaCodeList);
      this.allAreaCodeListShow = this.allAreaCodeList.filter(function(x) {
        return x.parentCode === key;
      });

    },
    //输入筛选
    changeSerArea(v) {
      // console.log(v);
      this.allAreaCodeListShow = this.allAreaCodeList.filter(function(x) {
        return x.code.indexOf(v) > -1;
      });
    },
    //选择
    selData(item) {
      // console.log(item);

      this.areaCodeShow = item.codeText;
      this.areaCodeTextShow = item.code;
      try {
        this.$refs.popover_areaCode.doClose();
      } catch (err) {
        console.log(err);
      }

      console.log(this.areaCodeShow,this.areaCodeTextShow);

      // this.$forceUpdate();

      this.$emit('selData', item);
      // this.$refs.phoneNumber.focus();
    },
    //获取银行取现方式信息
    // getData() {
    //    // let _this = this;
    //    this.loading = true;

    //    // formData.state = formData.state ? '0' : '1';
    //    this.$http.put(this.$urlConfig.OpenAccountBankMsgList, {
    //      accountType: this.accountType
    //    })
    //      .then(({ data }) => {
    //        console.log("获取银行取现方式成功");
    //        console.log(data);
    //        // this.$vux.loading.hide();
    //        this.loading = false;
    //        if (200 == data.code) {
    //          // this.dialogFormVisible = false;
    //          // this.getPageData();
    //          // this.$alert('恭喜,提现申请获取银行取现方式成功,正在处理...', this.$t('tips.tipsTitle'), {
    //          //   type: 'success',
    //          //   //confirmButtonText: '确定',
    //          // });
    //          this.listData = data.rows;
    //        } else {
    //          // if (!data.msg) {
    //          //   data.msg = "获取银行取现方式失败,请重试";
    //          // }
    //          // this.$alert(data.msg ? data.msg : '获取银行取现方式失败,请重试', this.$t('tips.tipsTitle'), {
    //          //   type: 'warning',
    //          //   //confirmButtonText: '确定',
    //          // });
    //        }
    //      })
    //      .catch((error) => {
    //        console.log(error);
    //        console.log("获取银行取现方式失败");
    //        this.loading = false;
    //        // this.$alert('获取银行取现方式失败,请重试！', this.$t('tips.tipsTitle'), {
    //        //   type: 'warning',
    //        //   //confirmButtonText: '确定',
    //        // });
    //      });
    //  },
  },

}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.selAreaKeyCon {
  ul {
    padding: 10px 0;

    li {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      // text-align: center;
      padding: 2px 8px;
      // width: 10px;
      // height: 10px;
      cursor: pointer;

      &:hover {
        // font-weight: bold;
        color: #e4393c;
      }

    }

    .activeAreaKey {
      color: #e4393c;
    }
  }
}

.areaCodeListCon {
  height: 200px;
  overflow: auto;

  ul {
    li {
      cursor: pointer;
      font-size: 16px;
      margin-bottom: 10px;
      display: flex;
      // justify-content: space-between;

      .areaCodeName {
        padding: 0 10px;
      }

      .areaCodeVal {
        padding: 0 10px;
      }
    }
  }

  // &::-webkit-scrollbar {
  //   width: 10px;
  // }
}

.areaCodeListCon::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.areaCodeListCon::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

.areaCodeListCon::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #EDEDED;
}

.line_separate {
  margin: 0px 0 10px 0;
  // display: flex;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px 0 5px;
    background: #fff;
  }

  .line_separate_icon {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 1px;
    border-top: 1px solid #e5e5e5;
    // margin-top: 5px;
  }
}

.AreaNameCon {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.AreaCodeCon {
  display: inline-block;
  vertical-align: middle;
  // width: 50px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;

  width: 35px;
}

// .simTable {
//   tr {
//     td,th {
//       text-align: center;
//       font-size: 16px;
//       line-height: 20px;
//     }
//   }
// }

</style>
